import React, { useState } from 'react';
import Coupon from '../components/Coupon';
import RulesPopup from '../components/RulesPopup';

const Header = ({
  user = {},
  rule = '',
}) => {
  const [showRulesPopup, setShowRulesPopup] = useState(false);

  const handleClickRules = (e) => {
    e.preventDefault();
    setShowRulesPopup(true);
  };

  return (
    <>
      <header id="header" className="header">
        <div className="header__left">
          <a href="#" className="header__rules text-gray-bg" onClick={e => handleClickRules(e)}>กฎกติกา</a>
        </div>
        <div className="header__right">
          <div className="header__user text-gray-bg text-truncate">
            ยินดีต้อนรับ : {user.nickname}
          </div>
          <div className="header__coupon text-gray-bg">
            คงเหลือ : <Coupon value={user.coupon}/>
          </div>
        </div>
      </header>
      {showRulesPopup && (
        <RulesPopup
          isOpen={showRulesPopup}
          rule={rule}
          onClose={e => setShowRulesPopup(false)}
        />
      )}
    </>
  );
};

export default Header;
