import React, { useEffect } from 'react';
import config from '../config/app';

const Coupon = ({ value = 'XXX', className = '', odometer = false }) => {
  useEffect(() => {
    const el = document.querySelector('.coupon span.odometer');

    if (el && value !== 'XXX' && odometer) {
      const od = new Odometer({
        el: el,
        format: 'd',
        theme: 'default'
      });

      setTimeout(() => {
        od.update(value);
      }, 0);
    }
  }, [value]);

  return (
    <span className="coupon">
      <img src={`${config.cdn}/img/coupon.png`}/>
      <span className={`${className} ${odometer ? 'odometer' : ''}`}>{value}</span>
    </span>
  );
};

export default Coupon;
