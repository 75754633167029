import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentUser } from 'authentication/actions';
import Header from '../Header';

const PageLayout = ({currentUser, location, children, check, getCurrentUser}) => {
  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      {!currentUser.loading && currentUser.login && (
        <div id="main" className={`page-${location.pathname != '/' ? location.pathname.replace('/', '') : 'home'}`}>
          <>
            <Header user={currentUser.user} rule={currentUser.rule} />
            {children}
          </>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  getCurrentUser
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
