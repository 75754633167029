import React from 'react';
import Modal from 'react-modal';
import Scrollbar from 'react-scrollbars-custom';
import config from '../config/app';

Modal.setAppElement('#root');

const Popup = ({
  isOpen = false,
  title = '',
  body = null,
  className = '',
  theme = 'default',
  outsideClose = true,
  hideCloseButton = false,
  onClose = () => {},
}) => {
  const afterOpenModal = () => {};
  const selfClose = (e) => {
    e.preventDefault();
    onClose(e);
  };

  const handleClickOutside = (e) => {
    if (outsideClose) {
      onClose(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={handleClickOutside}
      className={`modal-select modal-theme-${theme} ${className}`}
    >
      {theme === 'default' && (
        <>
          <img src={`${config.cdn}/img/popup-default-chibi-1.png`} className="modal-decor-left" />
          <img src={`${config.cdn}/img/popup-default-chibi-2.png`} className="modal-decor-right" />
        </>
      )}
      <div className="modal-wrapper">
        {theme === 'default' && title && (
          <h3 className="modal-title text-gold-bg">{title}</h3>
        )}
        {body && (
          <div className="modal-body">
            {theme === 'default' ? (
              <Scrollbar noScrollX={true}>
                <div className="modal-content">
                  {body}
                </div>
              </Scrollbar>
            ) : (
              <div className="modal-content">
                {body}
              </div>
            )}
          </div>
        )}
        {!hideCloseButton && (
          <a onClick={event => selfClose(event)} className="close" data-dismiss="modal" aria-label="Close">
            <img src={`${config.cdn}/img/icon-close.png`} />
          </a>
        )}
      </div>
    </Modal>
  )
};

export default Popup;
