import request from "lib/request"
import { showError, showSuccess } from "../lib/commons"

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const CLAIM         = 'CLAIM'
export const CLAIM_ERROR   = 'CLAIM_ERROR'
export const CLAIM_SUCCESS = 'CLAIM_SUCCESS'
export const IS_CLAIMING   = 'IS_CLAIMING'

export const SPIN         = 'SPIN'
export const SPIN_ERROR   = 'SPIN_ERROR'
export const SPIN_SUCCESS = 'SPIN_SUCCESS'
export const IS_SPINNING  = 'IS_SPINNING'

// User
export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if (response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
      } else {
        dispatch(getCurrentUserError(response))
      }
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  }
}

// Claim
export const isClaiming = () => {
  return {
    type: IS_CLAIMING
  }
}

export const claim = () => {
  return (dispatch, getState) => {
    dispatch(isClaiming());
    request('api/user/check', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if (response.status == 'successful') {
        const { items, selected_index: index } = getState().currentUser.user;
        showSuccess('receive_gift', {
          icon: items[index].image,
          name: items[index].package_name
        });
        dispatch(claimSuccess(response));
      } else {
        showError(response.error_code, getState().currentUser.user.coupon_need_topup);
        dispatch(claimError(response));
      }
    })
  }
}

export const claimSuccess = (response) => {
  return {
    type: CLAIM_SUCCESS,
    payload: response
  }
}

export const claimError = (response) => {
  return {
    type: CLAIM_ERROR,
    payload: response
  }
}

// Spin
export const isSpinning = () => {
  return {
    type: IS_SPINNING
  }
}

export const spin = (cb) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request('api/user/spin', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if (response.status == 'successful') {
        dispatch(spinSuccess(response));
        const { selected_index: index, coupon_need_topup: topup } = response.payload.user;
        cb(index, topup);
      } else {
        showError(response.error_code);
        dispatch(spinError(response));
      }
    })
  }
}

export const spinSuccess = (response) => {
  return {
    type: SPIN_SUCCESS,
    payload: response
  }
}

export const spinError = (response) => {
  return {
    type: SPIN_ERROR,
    payload: response
  }
}
