import Swal from 'sweetalert2';
import config from '../config/app';

export function showLogin(event = false) {
  if (event) {
    event.preventDefault()
  }

  Swal.fire({
    title: `<span class="text-gold-bg">ประกาศ</span>`,
    html: `
      <div class="popup-alert__content" style="min-height: 20vh;">
        <h5 class="popup-alert__message">กรุุณาล็อกอินเพื่อเข้าร่วมกิจกรรม</h5>
      </div>
    `,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: false,
    customClass: {
      popup: 'popup-alert popup-error'
    },
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
  }).then(res => res.value && (
    window.location = '/user/login/1'
  ))
}

export function showError(code, topup = null, title = 'แจ้งเตือน') {
  let msg = '';
  let cta = '';

  switch (code) {
    case 'missing_tencent_id':
      msg = 'คุณยังไม่มีไอดี RoV';
      break;

    case 'not_spin_yet':
      msg = 'กรุณากดสุ่มไอเทม';
      break;

    case 'already_spin':
      msg = 'คุุณได้สุ่มไอเทมแล้ว';
      break;

    case 'already_redeem':
      msg = 'คุณได้รับรางวัลแล้ว';
      break;

    case 'mission_incomplete':
      msg = `คุณต้องเติมเงิน ${topup} คูปองเพื่อรางวัล`;
      break;

    default:
      msg = 'เกิดข้อผิดพลาด (1) กรุณาลองใหม่อีกครั้ง';
      break;
  }

  return Swal.fire({
    title: `<span class="text-gold-bg">${title}</span>`,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">${msg}</h5>
        <p class="popup-alert__icon">!</p>
        ${cta}
      </div>
    `,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    closeButtonHtml: `<img src="${config.cdn}/img/icon-close.png" title="ปิด" />`,
    customClass: {
      popup: 'popup-alert popup-error'
    },
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
  })
}

export function showSuccess(code, data, title = 'ยินดีด้วย') {
  let msg = '';
  let content = '';

  switch (code) {
    case 'receive_gift':
      msg = 'คุณได้เติมคูปองสำเร็จและได้รับ:';
      content = `
        <div class="popup-alert__gift">
          <img src="${data.icon}" class="popup-alert__gift--icon" />
          <p class="popup-alert__gift--name">${data.name}</p>
        </div>
      `;
      break;
  }

  return Swal.fire({
    title: `<span class="text-gold-bg">${title}</span>`,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">${msg}</h5>
        ${content}
      </div>
    `,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    closeButtonHtml: `<img src="${config.cdn}/img/icon-close.png" title="ปิด" />`,
    customClass: {
      popup: 'popup-alert popup-success'
    },
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
  })
}

export function showConfirm(message = '', btnCancel = null, title = 'Thông báo', btnConfirm = 'Xác nhận') {
  return Swal.fire({
    title: title,
    'html': '<p>' + message + '</p>',
    confirmButtonText: btnConfirm,
    showCancelButton: btnCancel ? true : false,
    cancelButtonText: btnCancel,
    showCloseButton: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showClass: {
      popup: 'animated fadeInDown faster'
    },
    hideClass: {
      popup: 'animated fadeOutUp faster'
    },
    customClass: {
      popup: 'popup-small'
    },
  })
}

export function showMessage(message = '', btnCancel = null, title = 'Thông báo', btnConfirm = 'Xác nhận') {
  return Swal.fire({
    title: title,
    'html': '<p>' + message + '</p>',
    confirmButtonText: btnConfirm,
    showCancelButton: btnCancel ? true : false,
    cancelButtonText: btnCancel,
    showCloseButton: true,
    showClass: {
      popup: 'animated fadeInDown faster'
    },
    hideClass: {
      popup: 'animated fadeOutUp faster'
    },
    customClass: {
      popup: 'popup-small'
    },
  })
}

export function toObj(array, key) {
  var result = array.reduce(function(map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});

  return result;
}
