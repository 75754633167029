import React from 'react';
import Popup from './Popup';

const RulesPopup = ({
  isOpen = false,
  rule = '',
  onClose = () => {}
}) => {
  const handleClosePopup = (e) => {
    e.preventDefault();
    onClose(e);
  };

  const content = (
    <>
      <div className="rules">
        <div className="rules__content" dangerouslySetInnerHTML={{__html: rule}}></div>
      </div>
    </>
  );

  return (
    <>
      {isOpen && (
        <Popup
          theme="default"
          isOpen={isOpen}
          body={content}
          title="กฎกติกา"
          onClose={e => handleClosePopup(e)}
        />
      )}
    </>
  );
};

export default RulesPopup;
