import config from 'config/app'

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  CLAIM_ERROR,
  CLAIM_SUCCESS,
  IS_CLAIMING,

  SPIN_ERROR,
  SPIN_SUCCESS,
  IS_SPINNING,
} from './actions'

const initialState = {
  loading: false,
  login: false,
  user: {},
  items: {},
  rule: '',
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    // USER
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        rule: payload.rule,
        errorGlobal: ''
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        errorGlobal: 'กรุุณาล็อกอินเพื่อเข้าร่วมกิจกรรม',
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;

    // CLAIM
    case CLAIM_SUCCESS:
      return {
        ...state,
        isClaiming: false,
        user: payload.payload.user
      }
      break;
    case CLAIM_ERROR:
      return {
        ...state,
        isClaiming: false,
      }
      break;
    case IS_CLAIMING:
      return {
        ...state,
        isClaiming: true
      }
      break;

    // SPIN
    case SPIN_SUCCESS:
      return {
        ...state,
        isSpinning: false,
        user: payload.payload.user
      }
      break;
    case SPIN_ERROR:
      return {
        ...state,
        isSpinning: false,
      }
      break;
    case IS_SPINNING:
      return {
        ...state,
        isSpinning: true
      }
      break;

    // DEFAULT
    default:
      return state
  }
}
